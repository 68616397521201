<template>

  <section id="add-video-muro-digital">

    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 my-3">
          <font-awesome-icon 
            icon="video" 
            class="color-secondary"
          />
          Selecciona los videos que quieres añadir a la lista de reproducción.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <input
          type="file"
          id="video-load"
          @change="onFileLoad($event)"
          accept=".mp4,.avi,.mov,.wmv,.webm,.flv"
          style="display:none"
        />
        <label 
          for="video-load" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50"
        >
        <font-awesome-icon 
          icon="plus" 
          class="pe-2"
        />
          Cargar video
        </label>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 col-lg-6 input-search">
        <input 
          type="search" 
          class="form-control input-custom"
          placeholder="Ingresa nombre del video"
          v-model="title"
          @keypress.enter="onVideoSearch()"
        />
          <span class="input-search-icon">
            <font-awesome-icon icon="search"/>
          </span>
      </div>
      <div class="col-12 col-lg-6 d-flex align-items-center justify-content-end mt-3 mt-lg-0">
        <div 
          v-if="userHasVideoModule" 
          class="d-flex align-items-center"
        >
          <p class="mb-0">
            <font-awesome-icon 
              icon="video" 
              class="color-secondary pe-1"
            />
            Mostrar videos
          </p>
          <div class="d-flex mx-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDisabled1"
                id="flexRadioDisabled1"
                ref="flexRadioDisabled1"
                value="0"
                v-model="video_data"
                selected
              />
            </div>
            <label 
              for="flexRadioDisabled1" 
              class="form-check-label link-cursor"
            >
            Mural Digital
            </label>
          </div>
          <div class="d-flex mx-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDisabled2"
                id="flexRadioDisabled2"
                ref="flexRadioDisabled2"
                value="1"
                v-model="video_data"
              />
            </div>
            <label 
              for="flexRadioDisabled2" 
              class="form-check-label link-cursor"
            >
              Videos
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div 
          id="card-container" 
          class="card card-custom border-round-10 card-shadow border-0"
        >
          <div class="card-body">

            <div class="row mb-3">
              <div class="co-12 col-lg-8">
                <h5 class="card-title">
                  Listado de videos
                </h5>
                <p class="text-secondary mb-0">
                  Posiciónate sobre un video y selecciona entre previsualizar, añadir o eliminar.
                </p>
              </div>
              <div 
                v-show="video_data == 1"
                class="col-12 col-lg-4">
                <v-select 
                  class="selvue-custom"
                  :options="categorias"
                  placeholder="Selecciona una categoría"
                  label="nombre_categoria"
                  v-model="cat_selected"
                  :clearable="false"
                >
                </v-select>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <VideosContainer 
                  :videos="list_video" 
                  :pagination="pagination" 
                  @videoDeleted="onVideoListChange"
                  @playlistChange="onPlaylistChange" 
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 d-flex flex-column flex-lg-row justify-content-between align-lg-items-center">
                <div
                  :style="{ opacity: active_time ? 1 : 0.5 }"
                  class="card mb-3 mb-lg-0"
                >
                  <div class="card-body p-2">
                    <p class="mb-0 text-center text-lg-start">
                      <font-awesome-icon
                        :icon="['fa-regular', 'clock']"  
                        class="color-main"
                      />
                      Tiempo de duración
                      <span class="border color-main py-1 px-2 ms-1 border-round-10">
                        {{ tiempo_total }}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <button
                    class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                    @click="$router.go(-1)"
                  >
                    Volver
                  </button>
                  <button 
                    class="btn btn-custom-color-blue border-round-50 mw-100" 
                    @click="addVideoListToChannel"
                    :disabled="!active_button"
                  >
                    Añadir
                  </button>
                </div> 
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <Spinner v-show="show_spinner"/>
    <LoadingProgress 
      :value="loading_progress" 
      v-if="show_progress" 
    />

  </section>

</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import VideosContainer from "./VideosContainer.vue";
import Spinner from "../Spinner.vue";
import LoadingProgress from "../LoadingProgress.vue";
import { VideoAdapter } from "../../models/VideoAdapter";
import moment from 'moment';

export default {
  components: { VideosContainer, Spinner, LoadingProgress },
  data() {
    return {
      id: this.$route.params.id,
      active_button: false,
      tiempo_total: "00:00:00",
      active_time: false,
      video_data: 0,
      show_spinner: false,
      show_progress: false,
      loading_progress: 0,
      list_video: [],
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario: this.$ls.get("user").id_usuario,
      categorias: [],
      cat_selected: "",
      pagination: {
        actual_page: 1,
        page_count: 1,
        page_range: 3,
        click_handler: this.onPageChange,
        prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",
      },
      title: "",
      selected_videos: [],
      filesPerPage: "12"
    };
  },

  watch: {
    video_data() {
      if (this.video_data == 0) {
        this.list_video = this.repoVideosFiltered(0).map(video => new VideoAdapter(video));
        Object.assign(this.pagination, this.paginationDataForRepoVideos);
      }
      if (this.video_data == 1) {
        this.list_video = this.videoVideosFiltered(0).map(video => new VideoAdapter(video));
        Object.assign(this.pagination, this.paginationDataForVideoVideos);
      }
      return;
    },

    cat_selected() {
      this.getPaginatedVideoIdsFromVideos();
    },

    paginationDataForRepoVideos: {
      handler(val) {
        if (this.video_data == 0) {
          Object.assign(this.pagination, val);
        }
      },
      deep: true
    },
    paginationDataForVideoVideos: {
      handler(val) {
        if (this.video_data == 1) {
          Object.assign(this.pagination, val);
        }
      },
      deep: true
    },
    
  },

  computed: {
    ...mapGetters("sidebar", ["userHasVideoModule"]),
    ...mapState("videoModule", ["videoVideosPagination"]),
    ...mapGetters("videoModule", ["videoVideosFiltered", "paginationDataForVideoVideos"]),
    ...mapState("tvCorporativaModule", ["repoVideosPagination"]),
    ...mapGetters("tvCorporativaModule", ["repoVideosFiltered", "paginationDataForRepoVideos"]),
  },

  async mounted() {
    await this.getCategories();
    await this.getAllVideosFromRepo();
    await this.getAllVideosFromVideos();
    await this.getPaginatedVideoIdsFromRepo();
    await this.getPaginatedVideoIdsFromVideos();
  },
  
  methods: {
    ...mapActions("tvCorporativaModule", 
      ["setChannelLayoutVideos", "uploadFileRepositorio", "getFilesPagination", "getAllRepoFiles"]),
    ...mapActions("videoModule", ["getPagination", "getAllVideoVideos", "getCategoriesAction"]),

    onPlaylistChange(selected_videos) {
      this.selected_videos = selected_videos;
      if (selected_videos.length >= 1) {
        this.active_time = true;
        this.active_button = true;
      }
      if (selected_videos.length == 0) {
        this.active_time = false;
        this.active_button = false;
      }
      this.tiempo_total = this.sumTotalTime(selected_videos.map(video => video.tiempo_video));      
    },

    onFileLoad(event) {
      const fileElement = event.target;
      const file = fileElement.files[0]
      const fileType = file.type.split("/")[0];
      const fileName = file.name;
      const newVideo = {};
      newVideo.name = fileName;
      
      const reader = new FileReader();
      this.show_progress = true;
      if (fileType == "video") {
        if (file.size < 100 * 1024*1024) {
          reader.onload = (e) => {
            newVideo.base64 = e.target.result;
            newVideo.src    = e.target.result.split(",")[1];
            newVideo.type   = e.target.result.split(";")[0].split(":")[1];
  
            // Obtención de la duración del video
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
              window.URL.revokeObjectURL(video.src);
              newVideo.duration = video.duration;
              this.loadVideo(newVideo);
            }
            video.src = URL.createObjectURL(file);
          };

          reader.onprogress = (data) => {
            if (data.lengthComputable) {
              this.loading_progress = parseInt( ((data.loaded / data.total) * 100), 10 );
            }
          };

          reader.onloadend = () => {
            this.loading_progress = 0;
            this.show_progress = false;
            event.target.value = '';
          };

          reader.readAsDataURL(file);
        } else {
          this.loading_progress = 0;
          this.show_progress = false;
          this.toastVideoUploadMaxSizeError();
        }
      } else {
        this.loading_progress = 0;
        this.show_progress = false;
        this.toastVideoUploadExtensionError();
      }
    },

    async verifyVideoUploadStatus(url) {
      try {        
        const res = await fetch(url, { method: 'HEAD' });
        const status = res.status;
        if (status !== 200) {
          this.toastVideoURLError();
        }
      } catch (error) {
        this.toastConnectionError();
        console.error("Error:", error);
      }
    },

    async loadVideo(video) {
      const data = {
        id_empresa: this.id_empresa,
        id_usuario: this.id_usuario,
        tipo_archivo: "1",
        file: video
      };
      this.show_spinner = true;
      const uploadedFileObj = await this.uploadFileWithDelayAwareness(data);
      if (!uploadedFileObj) {
        this.toastVideoUploadError();
      } else {
        await this.verifyVideoUploadStatus(uploadedFileObj.url_archivo);
        this.toastVideoUploadSuccess();
        await this.onVideoListChange();
      }
      this.show_spinner = false;
    },

    async uploadFileWithDelayAwareness(uploadData) {
      let elapsedSeconds = 0
      const startUploadingTime = setInterval(() => {
        elapsedSeconds++;
        if (elapsedSeconds === 15) {
          alert("La carga del video está tomando mucho tiempo. Considera usar un compresor online seguro como https://tsubasa.js.org/");
        }
      }, 1000);
      const uploadedFileObj = await this.uploadFileRepositorio(uploadData);
      clearInterval(startUploadingTime);
      return uploadedFileObj;
    },

    addVideoListToChannel() {
      this.show_spinner = true;
      this.setChannelLayoutVideos({ videos: this.selected_videos, tiempo_total: this.tiempo_total });
      setTimeout(() => {
        this.show_spinner = false;
        this.toastVideoPlaylistAddSuccess();
        this.$router.go(-1);
        // this.$router.push({
        //   name: "CanalCorporativo-addContenido",
        //   params: {
        //     id: this.$route.params.id,
        //   },
        // });
      }, 2000);
    },

    async getCategories() {
      const payload = {
        idEmpresa: this.id_empresa,
        plataforma: "2",
      };

      this.categorias = await this.getCategoriesAction(payload);
      //FF
      this.categorias.unshift({
        __typename: "CategoriasVideos",
        id_categoria: "0",
        nombre_categoria: "Todas"
      })

      if (this.categorias === null) {
        this.toastGetVideosError();
      } 
    },

    onPageChange(page) {
      this.show_spinner = true;
      this.pagination.actual_page = page;
      if (this.video_data == 0) {
        this.getRepoFilesForPage(page);
      } else if (this.video_data == 1) {
        this.getVideoVideosForPage(page);
      }
      this.show_spinner = false;
    },

    async onVideoListChange() {
      // Reobtener lista de videos y paginación de {repo,videos} según video_data={0,1}
      // ignorando términos de búsqueda por texto
      if (this.video_data === 0) {
        await this.getPaginatedVideoIdsFromRepo();
      } else if (this.video_data === 1) {
        await this.getPaginatedVideoIdsFromVideos();
      }
      this.pagination.actual_page = 1;
    },

    async getAllVideosFromVideos() {
      const data = {
        idEmpresa: this.id_empresa,
        limite: this.filesPerPage,
        idCategoria: this.cat_selected.nombre_categoria == "Todas" || this.cat_selected == "" ? "0" : this.cat_selected.id_categoria,
        fechaInicio: "",
        fechaFinal: "",
      };

      const res = await this.getAllVideoVideos(data);
      if (!res) {
        this.toastGetVideosError();
      }
    },

    async getAllVideosFromRepo() {
      const data = {
        idEmpresa: this.id_empresa,
        limite: this.filesPerPage,
        tipoArchivo: "1"
      };
      const res = await this.getAllRepoFiles(data);
      if (!res) {
        this.toastGetVideosError();
      }
    },

    async getPaginatedVideoIdsFromVideos(nombre_video="") {
      this.show_spinner = true;
      const payload = {
          idEmpresa: this.id_empresa,
          nombre: nombre_video,
          fechaInicio: "",
          fechaFinal: "",
          idCategoria: this.cat_selected.nombre_categoria == "Todas" || this.cat_selected == "" ? "0" : this.cat_selected.id_categoria,
          limite: this.filesPerPage
      };
      await this.getPagination(payload);
      if (this.videoVideosPagination === null) {
        this.toastGetVideosError();
      } else {
        if (this.videoVideosPagination.length > 0) {
          this.getVideoVideosForPage(1);
        }
      }
      this.show_spinner = false;
    },

    async getPaginatedVideoIdsFromRepo(nombre_video="") {
      this.show_spinner = true;
      const data = {
        idEmpresa: this.id_empresa,
        tipoArchivo: "1",
        limite: this.filesPerPage,
        nombreArchivo: nombre_video
      };
      await this.getFilesPagination(data);
      if (this.repoVideosPagination === null) {
        this.toastGetVideosError();
      } else {
        if (this.repoVideosPagination.length > 0) {
          this.getRepoFilesForPage(1);
        }
      }
      this.show_spinner = false;
    },

    getVideoVideosForPage(page) {
      if (this.video_data == 1) this.list_video = this.videoVideosFiltered(page-1).map(video => new VideoAdapter(video));
    },

    getRepoFilesForPage(page) {
      if (this.video_data == 0) this.list_video = this.repoVideosFiltered(page-1).map(video => new VideoAdapter(video));
    },

    onVideoSearch() {
      if (this.video_data == 0) {
        this.getPaginatedVideoIdsFromRepo(this.title);
      } else if (this.video_data == 1) {
        this.getPaginatedVideoIdsFromVideos(this.title);
      }
    },

    sumTotalTime(tiempos) {
      const sum = tiempos.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());
      return moment.utc(sum.as('milliseconds')).format('HH:mm:ss');
    },

    toastVideoPlaylistAddSuccess() {
      this.$toast.open({
        message: "Tu lista de video ha sido agregada correctamente.",
        type: "success",
        duration: 5000,
        position: "top-right"
      });
    },
    toastVideoUploadExtensionError() {
      this.$toast.open({
        message:'El archivo seleccionado no es un video. Por favor selecciona un archivo con formato de video (.mp4, .avi, .mov, .wmv).',
        type:'warning',
        duration: 5000,
        position:'top-right'
      });
    },
    toastVideoUploadMaxSizeError() {
      this.$toast.open({
        message:'El video excede los 100 MB permitidos. Por favor selecciona un video con peso menor a 100 MB.',
        type:'warning',
        duration: 5000,
        position:'top-right'
      });
    },
    toastVideoUploadError() {
      this.$toast.open({
        message: "Falla al momento de cargar video.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },
    toastVideoUploadSuccess() {
      this.$toast.open({
        message: "El video ha sido cargado exitosamente.",
        type: "success",
        duration: 6000,
        position: "top-right",
      });
    },
    toastGetVideosError() {
      this.$toast.open({
        message: "Error en la obtención de datos. Por favor intenta nuevamente recargando la página.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },
    toastVideoURLError() {
      this.$toast.open({
        message: "El video se ha cargado con problemas. Por favor revisa el video cargado o intenta nuevamente.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },
    toastConnectionError() {
      this.$toast.open({
        message: "Error en la conexión. Por favor revisa tu conexión a internet.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });      
    }
  },
};
</script>