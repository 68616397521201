<template>
  <section 
    id="videos-sel-muro-digital"
    class="mt-3"
  >
    <h5 class="font-main-bold text-secondary border-bottom mb-3 pb-1">
      <font-awesome-icon 
        icon="table-list" 
        class="color-secondary"
      />
      Lista de reproducción
    </h5>
    <div class="alert alert-light border">
      <font-awesome-icon 
        icon="circle-arrow-down" 
        class="pe-1"
      />
      <strong class="font-main-bold">Revisa, ordena o elimina</strong> los videos añadidos.
    </div>

    <div class="row">
      <div class="col-12">
        <Draggable 
          class="carousel-custom"
          :animation="200"
          :list="selected_videos"
          :key="draggableComponentKey"
          @start="drag = true" @end="drag = false"
          >
          <div
            v-for="(video, i) in selected_videos" 
            :key="i"
            class="card border-0 border-round-10 card-shadow carousel-custom-item"
          >
            <div class="card-body">
              <img
                class="carousel-custom-item-img border-round-10"
                :src="video.url_imagen"
              />
              <div class="carousel-custom-item-remove">
                <span class="carousel-custom-item-remove-time">
                  {{ video.tiempo_video }}
                </span>
                <a 
                  @click="deleteVideoFromList(i)"
                  href="javascript:" 
                  class="carousel-custom-item-remove-icon"
                >
                  <font-awesome-icon icon="times"/>
                </a>
              </div>
              <div class="carousel-custom-item-title">
                {{ video.titulo_video | capitalize }}
              </div>
            </div>
          </div>
        </Draggable>
      </div>
    </div>

  </section>
</template>

<script>
  import Draggable from "vuedraggable";

  export default {
    props: {
      selected_videos: {
        type: Array,
      },
    },
    components: {
      Draggable
    },
    data() {
      return {
        draggableComponentKey: 0,
        carouselNarow: false
      };
    },
    watch: {
      selected_videos() {
        this.draggableComponentKey++;
      }
    },
    methods: {
      deleteVideoFromList(index) {
        this.$emit("deleteVideoFromList", index);
      },

      //FF
      resizeCarousel() {
        let sidebarNarrow = document.querySelector(".sidebar-narrow");
        let carousel = document.querySelector(".carousel-custom");
        if (sidebarNarrow){
          carousel.classList.add('carousel-custom-narrow');
        }
        else {
          carousel.classList.remove('carousel-custom-narrow')
        }
      }
    },
    mounted() {
      //FF
      let eventSidebar = document.querySelector(".sidebar-narrow-link");
      eventSidebar.addEventListener('click', this.resizeCarousel);  
      window.addEventListener('resize', this.resizeCarousel); 
    },
    updated() {
      this.resizeCarousel();
    },
    filters: {
      capitalize: function (value) {
      if (!value) return ''
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
    }
  };
</script>

<style>
</style>